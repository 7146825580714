.bundle-button button {
  margin-left: 15px;
  border: #008aff 2px solid;
  border-radius: 5px;
  background-color: var(--backgroundGray3);
  color: #008aff;
  padding-left: 10px;
  padding-right: 10px;
}

.ShopDashboard {
  display: flex;
  --space-margin: 30px;
  margin-top: var(--space-margin);

  .VisitorsSource {
    max-width: 350px;
    height: fit-content;
    margin-left: var(--space-margin);
  }

  > :not(.VisitorsSource) {
    flex-grow: 1;
  }

  .WebsiteVisitors {
    margin-bottom: var(--space-margin);

    .VisitorsChart {
      margin: 50px 10px 10px 10px;
      cursor: pointer;
    }
  }

  .VisitorsSource,
  .WebsiteVisitors,
  .SalesPerformance {
    background-color: var(--defcl);
    border-radius: 14px;
  }
}

.RichTextEditor {
  margin: 25px 0;

  .tox-tinymce {
    margin-top: 9px;
    border: none;
  }
  .tox .tox-statusbar {
    display: none;
  }
  .tox .tox-edit-area__iframe {
    background-color: transparent;
  }
}
.tox-notifications-container {
  display: none;
}
body {
  .jodit-properties__lock > svg,
  .jodit-properties__unlock > svg,
  .jodit-icon,
  .jodit-toolbar-button__trigger svg,
  .jodit-color-picker__native svg {
    stroke: #eee;
    fill: #eee;
  }
  .jodit-react-container {
    margin-top: 10px;
  }
  .jodit-workplace {
    background-color: #323d4e;
    padding: 0 15px;
    border-radius: 0 0 8px 8px;
    min-height: 100px;
  }
  .jodit-toolbar__box:not(:empty) {
    background-color: #323d4e;
    border-bottom: 1px solid #323d4e;
    border-radius: 8px 8px 0 0;
  }
  .jodit-toolbar-button__button:hover:not([disabled]),
  .jodit-toolbar-button__trigger:hover:not([disabled]),
  .jodit-toolbar-button__button[aria-pressed="true"]:not([disabled]),
  .jodit-dialog__panel {
    background-color: #273142;
  }
  .jodit-ui-button[aria-pressed="true"]:not([disabled]) {
    background-color: #1b2431;
  }
  .jodit-ui-button:hover:not([disabled]),
  .jodit-ui-button[aria-pressed="true"]:hover:not([disabled]),
  .jodit-ui-input__input,
  .jodit .jodit-input,
  .jodit .jodit-select {
    background-color: #323d4e;
  }
  .jodit-ui-input__wrapper,
  .jodit .jodit-input,
  .jodit .jodit-select {
    border: none;
  }
  .jodit-toolbar-button__button,
  .jodit-ui-button,
  .jodit-dialog__panel,
  .jodit-form__group select {
    color: #eee;
  }
  .jodit-toolbar-button_with-trigger_true:hover:not([disabled]) {
    border-color: #273142;
  }
  .jodit-popup__content {
    background: #263242;
  }
  .jodit-toolbar-editor-collection_mode_horizontal:after {
    opacity: 0;
  }
  .jodit-add-new-line {
    display: none;
  }
}

.ShopProductForm {
  background-color: var(--defcl);
  border-radius: 20px;
  margin: 20px 0;

  .container {
    min-height: 300px;
  }

  .addOptionButton {
    color: #008aff;
    font-weight: 800;
    cursor: pointer;
    font-size: 17px;
  }

  .deleteOptionButton {
    position: absolute;
    left: calc(100% + 14px);
    bottom: 27px;
    cursor: pointer;
  }

  .image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 158px;
    max-width: 158px;
    width: 100%;
    aspect-ratio: 1;
    background-color: var(--bdgclInp);
    border-radius: 6px;
    cursor: pointer;
    margin-right: 20px;
    margin-bottom: 20px;
    overflow: hidden;
    position: relative;

    &.loading {
      opacity: 0.4;
    }

    > svg {
      transform: scale(2.7);
    }

    > img {
      min-width: 100%;
      min-height: 100%;
      -o-object-fit: cover;
      object-fit: cover;
    }

    input {
      display: none;
    }

    &.overlay {
      .button-absolute {
        position: absolute;
        z-index: 1;
        display: none;

        &.delete-image {
          right: 5px;
          top: 5px;
        }
        &.left {
          left: 5px;
          bottom: 5px;
        }
        &.right {
          right: 5px;
          bottom: 5px;
        }
        &.right svg,
        &.left svg {
          transform: scale(0.8);
        }
      }

      &:hover {
        .button-absolute {
          display: block;
        }

        &::after {
          content: "";
          position: absolute;
          height: 100%;
          width: 100%;
          left: 0;
          top: 0;
          background-color: rgba(0, 0, 0, 0.3);
        }
      }
    }
  }

  .is-visible-toggle {
    margin-top: 10px;
    display: flex;
    align-items: center;

    p {
      margin-left: 12px;
      margin-bottom: 12px;
    }
  }

  .slug-slash {
    left: 9px;
    bottom: 27px;
  }

  .language,
  .rate {
    max-width: 200px;
    padding: 10px 0;
  }
}

.StatisticsDisplayer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  text-align: center;

  .title {
    font-size: 20px;
    margin: 0;
  }

  .value {
    font-size: 38px;
    font-weight: 800;
    margin: 0;
  }

  .rate {
    font-size: 14px;
    color: #a9adb3;
    margin: 0;

    .green {
      color: #2ecc71;
    }

    .red {
      color: #e74c3c;
    }
  }
}

.ChartBar {
  padding: 7px 10px;
  --bar-width: 100%;

  .texts {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .bar {
    height: 13px;
    margin-top: 3px;
    width: var(--bar-width);
    background-color: #008aff;
  }
}

.AlgerianMap {
  max-width: 100%;

  path {
    fill: var(--defcl);
  }
}

.ShopPublishedSuccess {
  text-align: center;
  svg {
    width: 100%;
    height: 280px;
  }
  h2 {
    font-weight: 400;
  }

  button {
    max-width: fit-content;
    padding: 10px 36px;
  }
}

@media only screen and (max-width: 640px) {
  .FlHg.StWdsc > #Container-root {
    padding-right: 21px;
    padding-left: 21px;

    > div > .InFlx.flxDrc.AlgnItm.FlWd.RlPs.lytWdp {
      flex-direction: column;
      width: 100%;
      padding: 0;

      > .InFlx.AlgnItm {
        width: 100%;
        margin-bottom: 36px;
      }

      h2 {
        font-size: 20px;
      }

      button {
        font-size: 14px;
      }

      .StAutMr.FlHg {
        width: 100%;

        .InvStMrtg.InFlx {
          margin-left: auto;
        }
      }
    }
  }

  .ShopDashboard {
    flex-direction: column;

    > div {
      max-width: 100%;

      .InFlx.flxDrc.AlgnItm.FlWd.RlPs.lytWdp {
        width: 91%;
        padding-left: 15px;
      }
    }

    .VisitorsSource {
      margin-left: 0;
      margin-top: 20px;
    }
  }

  .StatisticsDisplayer {
    grid-template-columns: 1fr;
    grid-row-gap: 63px;
  }

  .ShopProductForm {
    .grid {
      grid-template-columns: 1fr;
    }

    .ImagesList > div {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 10px;

      .image-container {
        margin: 0;
      }
    }

    .OptionsField {
      position: relative;
      padding: 10px 0;
      margin-bottom: 15px;
      width: 100% !important;

      &:before {
        --padding-x: 20px;
        content: "";
        position: absolute;
        top: 0;
        left: calc((-1) * var(--padding-x));
        height: 100%;
        width: calc(100% + (2 * var(--padding-x)));
        background-color: #3f4b5d;
        border: 1px solid #323d4e;
        border-radius: 15px;
      }
    }

    .deleteOptionButton {
      top: 10px;
      right: -9px;
      left: auto;
    }
  }

  .ProductBundle .BundleProduct {
    position: relative;
    padding: 15px 0;
    margin-bottom: 20px;

    &:before {
      --padding-x: 20px;
      content: "";
      position: absolute;
      top: 0;
      left: calc((-1) * var(--padding-x));
      height: 100%;
      width: calc(100% + (2 * var(--padding-x)));
      background-color: #3f4b5d;
      border: 1px solid #323d4e;
      border-radius: 15px;
    }

    .Flwd.InFlx.flxDrc.AlgnItm {
      justify-content: space-between;
      align-items: flex-end;
      .CrsPoi.InFlx.JstfCnt {
        margin-bottom: 15px !important;
      }
    }
  }
}

html[dir="rtl"] {
  .bundle-button button {
    margin-left: 0;
    margin-right: 15px;
  }

  .ShopDashboard .VisitorsSource {
    margin-right: var(--space-margin);
    margin-left: 0;
  }

  .AlgerianMap,
  .ShopPublishedSuccess svg {
    transform: rotateY(0);
  }

  .ShopProductForm {
    input[name="slug"] {
      text-align: left;
    }

    .slug-slash {
      left: 4px;
      bottom: 25px;
    }
  }

  .RichTextEditor svg {
    transform: rotateY(0);
  }
}
